<template>
  <div id="FourSeasons">
    <WhereWeAreMap :mapData="mapData" />
  </div>
</template>
  
  <script>
import WhereWeAreMap from "@/components/global/WhereWeAreMap";
import { getFourSeasonsMapData } from "@/data/sectionData/whereWeAreData/fourSeasonsMapData";

export default {
  name: "CeasarsPalace",
  components: {
    WhereWeAreMap,
  },
  data() {
    return {
      mapData: getFourSeasonsMapData(),
    };
  },
};
</script>
  
  <style lang="scss scoped">
</style>