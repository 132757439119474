export const getFourSeasonsMapData = () => {
  return [
    {
      mapBg: require("@/assets/maps/bg-caesars-route.jpg"),
      mapLine: "FourSeasonsPath",
      venueBg: require("@/assets/maps/four-seasons.png"),
      venue: "Four Seasons",
      distance: "1.1",
      singleLineVenue: false,
      threeLineVenue: true,
    },
  ];
};
